.navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 192, 23);
    border-bottom: 1px solid black;
    height: 9%;
    min-width: 100%;
    min-height: 4vw;
    position: fixed;
    top: 0;
    z-index: 99;
}

.navBar-right {

    margin-right: 8vw;
    display: flex;
    min-height: 10vh;
    align-items: center;

}

.navBar-left {
    margin-left: 14.5vw;
}

.navBar-left-inner {
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.navBar-left-inner>img {
    padding-left: 10px;
}

.navBar-left-inner>p {
    font-family: Charter-Regular;
    text-decoration: none;
    font-size: 30px;
    margin: 0;
    color: black;
    font-weight: bolder;
}

.logo {
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding-top: 4%;
    padding-right: 8px;
}

.get-started {
    border: 3px solid black;
    border-radius: 40px;
    text-decoration: none;
    color: white;
    background-color: rgb(32, 32, 32);
    font-family: Helvetica;
    font-size: 14px;
    height: 38px;
    width: 105px;
    cursor: pointer;
}

.get-started:hover {
    background-color: black;
    color: white;
}

.sign-in {
    font-family: Helvetica;
    padding-top: 4px;
    padding-bottom: 0;
    padding-right: 20px;
    cursor: pointer;
    color: rgb(71, 71, 71);
}

.aboutPageBtn {
    font-family: Helvetica;
    padding-top: 4px;
    padding-bottom: 0;
    padding-right: 20px;
    cursor: pointer;
    /* font-size: 14px; */

}

.aboutPageBtn:visited {
    color: black;
    text-decoration: none;

}

button {
    background-color: transparent;
    border: none;
}

a.aboutUsLink {
    text-decoration: none;
    font-family: Helvetica;
    color: rgb(71, 71, 71)
}

a.aboutUsLink:hover {
    text-decoration: none;
    font-family: Helvetica;
    color: black;
}
