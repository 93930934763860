.biggestResponseContainer {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
}

.responsesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.individualResponses {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 90px;
    margin-top: -20px;
}

.responder {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.responderProfile {
    font-size: 1px
}

.responderName {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-family: Helvetica;
    margin-left: 10px;
    margin-top: 8px
}

.responseBody {
    height: 45%;
    text-overflow: ellipsis;
    font-family: Helvetica;
    overflow: hidden;
    font-size: 16px
}

.responseActions {
    justify-self: flex-end
}

.createResponse {
    margin-bottom: 100px;
    width: 100%;
    font-size: 20px;
}

.createResponseHead {
    font-size: 20px;
}

.create-response-submit {
    margin-top: 10px
}

.createResponseErrors {
    font-size: 16px
}


.responseTotalClaps {
    margin-top: 10px;
    font-size: 15px;
    font-family: Helvetica;
    display: flex;
    align-content: center;
}

img.clapEmojiResponse {
    height: 17.5px;
    width: 17.5px;
    filter: invert(54%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(94%) contrast(84%);


}

img.clapEmojiResponse:hover {
    height: 17.5px;
    width: 17.5px;
    filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(257deg) brightness(104%) contrast(104%)


}

button {
    cursor: pointer;
    color: grey;
    font-family: Helvetica;
}

button:hover {
    color:black;

}

input {
    font-family: Helvetica;


}


/* img.clapEmojiResponse#storyDetailsClap {
    height: 30px;
    width: 30px;
    color: grey;

}

img.clapEmojiResponse#storyDetailsClap:hover {
    height: 30px;
    width: 30px;
    color: black;
} */
