.allStoriesContainer {
    margin-top: 18vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createStoryBtn {
    height: 60px;
    align-self: flex-start;
    margin-left: 0px;
    justify-self: flex-start;
    font-size: 30px;
    width: 200px;
    margin-bottom: 20px;
    color: grey;
    cursor: pointer;
}

.createStoryBtn:hover {
    color: black;
}

.storyImage {
    width: 250px;
    height: 200px;
    object-fit: cover;
}

.storyCard {
    display:flex;
    flex-direction: row;
    width: 100%;
    min-height: 8vh;
    justify-content: space-between;

}

.allStoriesWrapper {
    border-bottom: 0.1px solid rgba(211, 211, 211, 0);
    margin-top: 1%;
    padding-bottom: 2%;
    margin-bottom: 2%;
    width: 45vw;
    min-height: 8vh;
}

.allStoriesWrapper:hover {
    border-bottom: 0.1px solid lightgray;
}

.left {
    width: 70%;
    height: 100%;
}

.right {
    margin: 0;
}

.title {
    margin: 0;
    text-decoration: none;
    color: black;
    font-family: Helvetica;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 8px;
    margin-top: 10px;
}

#profile-review {
    margin-right: 8px;
}

.storytext {
    white-space: pre-wrap;
    font-size: 16px;
    font-family: Helvetica;
    color: grey;
    min-height: 30%;
    overflow: hidden;
}
