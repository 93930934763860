.banner-wrapper {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    justify-content: space-between;
    background-color: rgb(255, 192, 23);
    border-bottom: 1px solid black;
    margin-bottom: 40px;
    min-width: 100vw;
    /* padding: 0 2rem 0 5rem; */
}

.banner-animation {
    padding-top: 3%;
    /* padding-right: 10%; */
    height: 100%;
    /* width: 100%; */
    margin-top: 1%;
    margin-right: 0;
    /* left: 20px; */
    /* z-index: 1; */
}

.banner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 5%;
    padding-left: 10%;


}


#first {
    font-family: Charter-Bold;
    font-size: 80px;
    margin-bottom: 0;
    /* z-index: 2; */
    /* min-width: 600px; */
}

#second {
    font-family: Helvetica;
    font-size: 1.6vw;
    margin-bottom: 0;
}

#third {
    font-family: Helvetica;
    font-size: 1.6vw;
    margin-top: 0;
    margin-bottom: 4%;
}

#start-reading {
    margin-top: 10%;
    height: 45px;
    width: 30%;
    font-size: 1.4rem;
    border: 3px solid black;
    border-radius: 40px;
    background-color: rgb(32, 32, 32);
    color: white;
    font-size: 1.1vw;
    margin-bottom: 10%;
    cursor: pointer;
}

#start-reading:hover {
    margin-top: 10%;
    height: 45px;
    width: 30%;
    font-size: 1.4rem;
    border: 3px solid black;
    border-radius: 40px;
    background-color: black;
    color: white;
    font-size: 1.1vw;
    margin-bottom: 10%;
    cursor: pointer;
}

img.trending-on-hard {
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    top: 5px;

}

p.trending-on-hard-text {
    font-size: 16px;
    font-weight: 900;
    font-family: Helvetica;
    vertical-align: center;
    /* margin-top: 100px; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin: auto;
    width: 60%;


}

.trending-stories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: start;
    width: 60%;
    margin: auto;
    margin-top: 2%;
    row-gap: 2ch;

}



.trending-story-wrapper {
    display: flex;
    flex-direction: row;
}

.userinfo {
    display: flex;
    margin: 0;
    text-decoration: none;
    color: black;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 6px;
    align-items: center;
    /* line-height: 5px; */
    /* border: 1px black solid; */
}

.storytitle {
    margin: 0;
    text-decoration: none;
    color: black;
    font-family: Helvetica;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 8px;
}

.landing-storytext {
    white-space: pre-wrap;
    font-size: 16px;
    font-family: Helvetica;
    color: grey;
    overflow: hidden;
    height: 34.2%;
}

.storydate {
    margin: 0;
    text-decoration: none;
    color: rgb(149, 149, 149);
    font-family: Helvetica;
    font-size: 13px;
    padding-bottom: 8px;
}

.trending-number {
    font-family: Helvetica;
    font-weight: 700;
    color: rgb(230, 230, 230);
    font-size: 28px;
    padding-right: 10px;
}

.trending-story {
    display: flex;
    flex-direction: column;
}

.trending-stories-wrapper {
    border-bottom: 1px solid rgb(217, 217, 217);
    padding-bottom: 2%;
}

.remaining-stories-wrapper {
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    height: 100px;
    /* width: 40vw; */
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;

}

.remaining-stories-divider {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    /* padding-bottom: 20px; */
    min-width: 50%;
    /* border: 1px solid black */
    border-bottom: 0.1px solid rgba(211, 211, 211, 0);
    /* border-right: 0.1px solid rgba(211, 211, 211, 0); */
}

.remaining-stories-divider:hover {
    border-bottom: 0.1px solid lightgray;
    /* border-right: 0.1px solid lightgray; */
}

#remaining-stories-left {
    max-height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    padding-bottom: 2%;
    padding-top: 2%;
}

/* #remaining-stories-left:hover {
    box-shadow: 1px 1px 1px rgb(194, 194, 194);
} */

.remaining-stories-right {
    width: 200px;
    height: 100%;
    align-items: center;
    display: flex;
}

.remaining-stories-right>img {
    width: 200px;
    height: 170px;
    margin: 0;
    padding: 0;
    object-fit: cover;
}
