.allMyContainer {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 800px;
}

.myStoriesContainer {
    margin-top: 10px;
    margin-left: -0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    height: 100%;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: center;
}

.header {

    /* margin-top: 20px; */
    font-size: 45px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Helvetica;
    min-width: 28%;
}

a.write-a-story{
    text-align: center;
    width: 100%;
    color: white;

}

a.write-a-story:hover{
    text-align: center;
    width: 100%;
    color: black;

}

.addStoryBtn {
    border-radius: 99em;
    background-color: rgba(58, 155, 56, 1);
    color: white;
    width: 15%;
    text-decoration: none;
    height: 20%;
    padding: 2%;
    cursor: pointer;
    font-family: Helvetica;
    display: flex;
    align-items: center;
    text-align: center;
}

.addStoryBtn:hover {
    color: black;
}

.story-card-mystories {
    border-bottom: 1px solid rgba(255,255,255,0);
    margin-top: 20px;
    padding-top: 5px;
    max-height: 30%;
    width: 100%;
    padding-bottom: 20px;
}

.story-card-mystories:hover {
    margin-top: 20px;
    padding-top: 5px;
    max-height: 30%;
    width: 100%;
    /* border-top: 1px solid lightgray; */
    border-bottom: 1px solid lightgray;
}

.story-stuff-mystories {
    min-height: 80%;
    display: flex;
    flex-direction: column
}

.button-container {
    min-height: 20%;
}

.storyTitle-mystories {
    margin-top: 5px;
    font-size: 20px;
    color: black;
    font-weight: bold;
    max-height: 20%

}

.mystories-test {
    min-height: 80%;
}

.storyBody-mystories {
    padding-top: 3px;
    max-height: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
            line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black;
    white-space: normal;
    font-family: Helvetica;
    font-size: 16px;
    /* word-break: break-all */
    margin: 0;
}

.divider {
    min-height: 100%;
    max-height: 100%
}

.userinfo-mystories {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 20%;
    color: black;
    font-family: Helvetica;
    margin: 0;
    margin-bottom: 10px;
}

.storyTitle-mystories {
    max-height: 20%;
    margin-bottom: 10px;

}


.deleteBtn {
    margin-top: 20px;
    margin-left: -5px;
    cursor: pointer;
    font-family: Helvetica;
    color: grey;
}

.editBtn {
    cursor: pointer;
    font-family: Helvetica;
    color: grey;
}

.deleteBtn:hover {
    color: black;
}

.editBtn:hover {
    color: black;
}

.following-button {
    font-size: 20px;
    color: rgb(43, 43, 43);
    font-family: Helvetica;
    cursor: pointer;
}

.following-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: fit-content;
    height: 500px;
    background-color: rgba(255, 255, 255, 1);
    min-width: 450px;
    min-width: 425px;
    overflow: hidden;
}

.single-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Helvetica;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.following-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
}

.following-unfollow-button {
    color: white;
    background-color: rgb(149, 149, 149);
    border-radius: 20px;
    cursor: pointer;
    width: 80px;
    height: 30px;
}

.following-unfollow-button:hover {
    color: rgb(38, 38, 38);
}

.follow-unfollow-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
}
