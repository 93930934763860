.therealbiggestcontain {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vw;
}


.biggestContainer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    height: 100px;
}

.authorInfo {
    width: 30%;
}

.soloAuthorName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 30px;
    font-weight: bold;
    height: 70%;
    width: 300px;

}

.followContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px
}

.fa-user-circle {
    font-size: 35px;
}

.authorsFirstandLast {
    margin-left: 5px;
    width: 100%;
}

.authorFollowers {
    /* width: 10%; */
    display: flex;
    align-items: center;
    font-size: 18px;
    color: gray

}

.followBtn {
    border-radius: 99em;
    background-color: rgba(58, 155, 56, 1);
    color: white;
    width: 40%;
    padding: 5%;
    cursor: pointer;
    font-family: Helvetica;
}

.followBtn:hover {
    color: black;
}

.clapsContainer {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 100%;
    align-self: flex-end;
    justify-content: flex-end;
    text-align: right;
}

.totalClaps {
    height: 100%;
    width: 50%;
    font-size: 19px;
    margin: 0;
    margin-top: 6%;
}


.clapEmoji {
    padding-top: 4px;
    color: black;
    cursor: pointer;
    height: 30px;
    width: 30px;
    filter: invert(54%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(94%) contrast(84%);
}

.clapEmoji:hover {
    color: black;
    cursor: pointer;
    height: 30px;
    width: 30px;
    filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(257deg) brightness(104%) contrast(104%)
}

.clapBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 30%;
    cursor: pointer;
}



.story-content {
    margin-top: 30px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Helvetica;
}

.storyDetailTitle {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Helvetica;
}

.soloStoryImg {
    margin-top: 30px;
    height: 420.69px;
    width: 98%;
    object-fit: cover;
}

.storyDetailStory {
    white-space: pre-wrap;
    font-family: Helvetica;
    margin-top: 30px;
    width: 98%;
    font-size: 20px;
    line-height: 32px;
}

.responseEmoji {
    padding-top: 4px;
    padding-left: 5px;
    color: black;
    cursor: pointer;
    height: 30px;
    width: 30px;
    filter: invert(54%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(94%) contrast(84%);


}

.responseEmoji:hover {
    padding-top: 4px;
    padding-left: 5px;
    color: black;
    cursor: pointer;
    height: 30px;
    width: 30px;
    filter: invert(0%) sepia(3%) saturate(0%) hue-rotate(257deg) brightness(104%) contrast(104%)



}

.storyDetailsRightSide {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.responseModal {
    width: 20%;
    align-self: flex-end;

}
